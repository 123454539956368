<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h4 class="title">Profile</h4>
          <div>
            <button class="btn btn-save">Simpan</button>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col-md-2" style="padding: 0px">
              <div class="col-left">
                <div
                  class="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    class="nav-link active"
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      class="icon-detail"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 18C5 15.2386 7.23858 13 10 13H14C16.7614 13 19 15.2386 19 18V19.2C19 19.6418 18.6418 20 18.2 20H5.8C5.35817 20 5 19.6418 5 19.2V18Z"
                        fill="white"
                        stroke="#898989"
                        stroke-width="2"
                      />
                      <circle
                        cx="12"
                        cy="6"
                        r="4"
                        fill="white"
                        stroke="#898989"
                        stroke-width="2"
                      />
                    </svg>

                    Detail</a
                  >
                  <a
                    class="nav-link"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.4355 10.7822C22.4252 10.6697 22.3731 10.5651 22.2895 10.4892C22.2059 10.4132 22.0968 10.3714 21.9838 10.372H12.5108C11.8865 7.97967 9.71382 6.20703 7.12854 6.20703C4.05774 6.20703 1.56006 8.70471 1.56006 11.7755C1.56006 14.847 4.05774 17.344 7.12854 17.344C9.87534 17.344 12.1573 15.3443 12.6101 12.7259H14.7197V15.73H14.7231C14.7313 15.8445 14.7825 15.9516 14.8665 16.0299C14.9505 16.1082 15.0609 16.1518 15.1757 16.1519C15.182 16.1519 15.1875 16.1505 15.1937 16.1502V16.1529H16.6577V16.1522C16.7726 16.1521 16.8831 16.1086 16.9671 16.0303C17.0511 15.952 17.1023 15.8448 17.1104 15.7302H17.1142V12.7262H18.3092V17.3709H18.3128C18.3211 17.4854 18.3724 17.5925 18.4564 17.6709C18.5404 17.7492 18.6508 17.7929 18.7657 17.7933L18.7707 17.7928H20.2376L20.2426 17.7933C20.3574 17.7929 20.4679 17.7491 20.5518 17.6708C20.6358 17.5925 20.6871 17.4854 20.6955 17.3709H20.6991V12.7259H21.9843V12.7254C22.1052 12.7252 22.2211 12.6771 22.3066 12.5916C22.3921 12.506 22.4402 12.3901 22.4403 12.2692H22.4401V10.7819H22.4355V10.7822ZM7.12854 15.0078C5.36742 15.0078 3.93462 13.5741 3.93462 11.8132C3.93462 10.0521 5.36742 8.61855 7.12854 8.61855C8.88942 8.61855 10.3215 10.0521 10.3215 11.8132C10.3217 13.5743 8.88942 15.0078 7.12854 15.0078Z"
                        fill="#898989"
                      />
                    </svg>
                    Kata Sandi</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-10" style="padding: 0px">
              <div class="col-right">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                    style="padding: 50px"
                  >
                    <div class="row">
                      <div class="col-md-3">
                        <div class="avatar text-center">
                          <img src="/img/avatar/ava.jpg" alt="" />
                          <div class="button mt-4">
                            <button class="btn">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63V5.63Z"
                                  fill="#68CAF8"
                                />
                              </svg>
                            </button>
                            <button class="btn">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                                  fill="#FF5252"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="form-group">
                          <label for="inputAddress2">Nama :</label>
                          <input
                            type="text"
                            class="form-control align-self-center"
                            id="inputAddress2"
                            placeholder="Masukkan Nama"
                          />
                        </div>

                        <div class="form-group">
                          <label for="inputAddress2">Email :</label>
                          <input
                            type="email"
                            class="form-control align-self-center"
                            id="inputAddress2"
                            placeholder="Masukkan Email"
                          />
                        </div>

                        <div class="form-group">
                          <label for="inputAddress2">Nomor Telepon :</label>
                          <input
                            type="text"
                            class="form-control align-self-center"
                            id="inputAddress2"
                            placeholder="Masukkan Nomor Telepon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                    style="padding: 50px"
                  >
                    <div class="form-group">
                      <label for="inputAddress2">Kata Sandi Sekarang :</label>
                      <input
                        type="password"
                        class="form-control align-self-center"
                        id="inputAddress2"
                        placeholder="Masukkan Kata Sandi"
                      />
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kata Sandi Baru :</label>
                      <input
                        type="password"
                        class="form-control align-self-center"
                        id="inputAddress2"
                        placeholder="Masukkan Kata Sandi Baru"
                      />
                    </div>

                    <div class="form-group">
                      <label for="inputAddress2">Ulangi Kata Sandi :</label>
                      <input
                        type="password"
                        class="form-control align-self-center"
                        id="inputAddress2"
                        placeholder="Ulangi Kata Sandi"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
  },

  data() {
    return {
      dateOut: null,
      OptionsBank: ["BCA", "Mandiri", "BNI"],
      OptionsGender: ["Laki - laki", "Perempuan"],
      OptionsProvince: ["DIY", "Jawa Tengah"],
      OptionsDistrict: ["Boyolali", "Surakarta"],
      OptionsDistricts: ["Jebres", "Lawean"],
      OptionsDepartement: ["Audio Visual", "IT"],
      OptionsPositions: ["Frontend Devloper", "UI/UX Designer"],
      OptionsStatusEmployee: ["Tetap", "Kontrak"],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    $("#tableDepartment").DataTable({
      info: false,
    });
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.button .btn {
  padding: 7px;
}

.button .btn:first-child {
  border: 1px solid #68caf8;
  margin-right: 20px;
}

.button .btn:first-child:hover {
  background-color: #68caf8;
}

.button .btn:first-child:hover svg path,
.button .btn:last-child:hover svg path {
  fill: #fff;
}

.button .btn:last-child {
  border: 1px solid #ff5252;
}

.button .btn:last-child:hover {
  background-color: #ff5252;
}

.nav-pills .nav-link:hover svg path,
.nav-pills .nav-link.active svg circle,
.nav-pills .nav-link.active svg path {
  fill: #000 !important;
}

.nav-pills .nav-link:hover {
  color: #000;
}

.nav-pills .nav-link:hover .icon-detail path,
.nav-pills .nav-link:hover .icon-detail circle,
.nav-pills .nav-link.active .icon-detail circle,
.nav-pills .nav-link.active .icon-detail path {
  stroke: #000;
  fill: #fff !important;
}

.form-label {
  max-width: 252px;
  width: 197px;
}

.avatar img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.form-edit {
  width: 100%;
}

.col-left {
  padding: 30px 5px;
  border: 1px solid #eceff1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}

.col-right {
  border: 1px solid #eceff1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title-detail {
  padding: 30px;
}

.text {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  width: 488px;
  max-width: 236px;
}

.modal .text {
  color: #000;
  font-size: 13px;
}

.value {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.info-detail {
  padding: 30px;
}

.col-left hr {
  margin: 20px 0px;
}

.name-employee {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
}

.position {
  color: #c6c6c6;
  font-size: 14px;
  margin-top: 0.5rem;
}

.menu .nav-link {
  color: #000;
}

.menu .nav-link.active {
  background: #68caf8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}

.menu {
  border: none;
  background: none;
}

.list-menu {
  margin-top: 30px;
}

ul li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav-pills {
  line-height: 30px;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  padding: 63px 50px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #000;
}

.nav-pills .nav-link {
  color: #898989;
}

.nav-pills .nav-link svg {
  margin-right: 10px;
}
</style>
